/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const imagesPrefix =
  "https://raw.githubusercontent.com/zanardeiras/professor/main/";

export default [
  {
    image: `${imagesPrefix}/whatsapp_chat.jpg`,
    name: "Rotina diária",
    route: "/pages/landing-pages/ferteus-ia",
  },
  {
    image: `${imagesPrefix}/codigo-aws.jpg`,
    name: "Auxilio de tarefas",
    route: "/pages/landing-pages/ferteus-ia",
  },
  {
    image: `${imagesPrefix}/vendedor2.jpg`,
    name: "Programação de vendas",
    route: "/pages/landing-pages/ferteus-ia",
  },
  {
    image: `${imagesPrefix}/consulta-dados.jpg`,
    name: "Consulta de dados",
    route: "/pages/landing-pages/ferteus-ia",
  },
];
