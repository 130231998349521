// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-ct-dark.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "AutoData",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "#",
    },
    {
      icon: <TwitterIcon />,
      link: "#",
    },
    {
      icon: <GitHubIcon />,
      link: "https://github.com/openai",
    },
    {
      icon: <YouTubeIcon />,
      link: "#",
    },
  ],
  menus: [
    {
      name: "Mais",
      items: [
        { name: "Sobre nós", href: "pages/landing-pages/about-us" },
        { name: "Teste Agora", href: "pages/landing-pages/ferteus-ia" },
        { name: "Adquira já", href: "https://whatsa.me/5534991883665" },
        { name: "blog", href: "pages/landing-pages/author" },
      ],
    },
    {
      name: "Recursos",
      items: [
        //{ name: "Exemplos", href: "#" },        
        { name: "Funções", href: "#" },
        { name: "Documentação", href: "https://platform.openai.com/docs/overview" },
      ],
    },
    {
      name: "Ajuda",
      items: [
        { name: "Contate-nos", href: "pages/landing-pages/contact-us" },
        { name: "FAQ", href: "pages/landing-pages/author" },
        { name: "Serviços Customizados", href: "https://whatsa.me/5534991883665" },
        { name: "Apoiadores", href: "#" },
      ],
    },
    {
      name: "legal",
      items: [
        { name: "termos de uso", href: "#" },
        { name: "política de Privacidade", href: "#" },
        { name: "licenças (EULA)", href: "#" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} Desenvolvimento de Inteligencia Artificial{" "}
      <MKTypography
        component="a"
        href="pages/landing-pages/about-us"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        AutoData
      </MKTypography>
      .
    </MKTypography>
  ),
};
