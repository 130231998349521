
const FerteusBox = () => {
    return (
        <iframe
          title="Chatbot"
          src="https://www.chatbase.co/chatbot-iframe/vCsZoTTez8qnABaHgHZE6"
          width="100%"
          style={{ height: '50%', minHeight: '650px' }}
          frameBorder={0}
        ></iframe>  
      );
    };


export default FerteusBox