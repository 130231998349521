

// Material Kit 2 React components
import MKBox from "components/MKBox";
import FerteusBox from "components/FerteusBox";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import MKTypography from "components/MKTypography";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Image
import bgFerteus from "assets/images/bgFerteus2.jpg";

function Ferteus() {
  return (
    <>
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgFerteus})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
        
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
          <MKTypography
  variant="h1"
  color="white"
  mt={-6}
  mb={1}
  sx={({ breakpoints, typography: { size } }) => ({
    [breakpoints.down("md")]: {
      fontSize: size["3xl"],
    },
    // Adicionando sombras ao texto
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',  // Ajuste os valores conforme necessário
  })}
> 
  Bem-vindo ao Projeto Ferteus
</MKTypography>

<MKTypography
  variant="body1"
  color="white"
  textAlign="center"
  px={{ xs: 6, lg: 12 }}
  mt={1}
  sx={{
    
    // Adicionando sombras ao texto
    textShadow: '2px 2px 8px rgba(0, 0, 0, 0.5)',  // Ajuste os valores conforme necessário
  }}
>
  Que tal fazer algumas perguntas?
</MKTypography>

          </Grid>
        </Container>
      </MKBox>
      <FerteusBox> </FerteusBox>
      <MKBox position="fixed" top="0.5rem" width="100%">
        <DefaultNavbar
          routes={routes}
          action={{
            type: "external",
            route: "https://whatsa.me/5534991883665",
            label: "Contato",
            color: "info",
          }}
        />
      </MKBox>      
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>

    </>
  );
}
export default Ferteus;
