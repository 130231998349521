/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";
import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";

// Images
import post1 from "assets/images/examples/testimonial-6-2.jpg";
import post2 from "assets/images/examples/testimonial-6-3.jpg";
import post3 from "assets/images/examples/blog-9-4.jpg";
import post4 from "assets/images/examples/blog2.jpg";

function Places() {
  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid container item xs={12} lg={6}>
          <MKTypography variant="h3" mb={6}>
            Veja as postagens do nosso Blog
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post1}
              title="Subida para os R$65 Milhoes"
              description="Veja como a StartUP AIRBNB criou um modelo de nogocios LandFree e alcançou faturamentos impressionantes ..."
              action={{
                type: "internal",
                route: "/pages/blogs/author",
                color: "info",
                label: "Leia Mais",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post2}
              title="Open IA machine learning"
              description="Se você sempre desejou implementar na sua empresa uma IA de forma facil? agora voce pode..."
              action={{
                type: "internal",
                route: "/pages/blogs/author",
                color: "info",
                label: "Leia Mais",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post3}
              title="Construções Inteligentes"
              description="Veja como Empresas de engenharia estão usando Inteligencia artifical construir casas modernas..."
              action={{
                type: "internal",
                route: "/pages/blogs/author",
                color: "info",
                label: "Leia Mais",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <BackgroundBlogCard
              image={post4}
              title="Restaurando o Verde"
              description="Projeto esta restaurando florestas no Canada usando plantaçoes Inteligentes"
              action={{
                type: "internal",
                route: "/pages/blogs/author",
                label: "Leia Mais",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Places;
